
#UserAgent
_ua = do ->
  IE = document.uniqueID
  ltIE6 = typeof window.addEventListener is undefined and typeof document.documentElement.style.maxHeight is undefined
  ltIE7 = typeof window.addEventListener is undefined and typeof document.querySelectorAll is undefined
  ltIE8 = typeof window.addEventListener is undefined and typeof document.getElementsByClassName is undefined
  ltIE9 = IE and typeof window.Worker is undefined
  IE6 = IE and ltIE6
  IE7 = IE and ltIE7 and not ltIE6
  IE8 = IE and ltIE8 and not ltIE7 and not ltIE6
  IE9 = IE and ltIE9 and not ltIE8 and not ltIE7 and not ltIE6
  IE10 = IE and not ltIE9 and not ltIE8 and not ltIE7 and not ltIE6
  Webkit = not document.uniqueID and not window.opera and not window.sidebar and not window.orientation and window.localStorage
  Safari = Webkit and navigator.vendor.search(/apple/i) isnt -1
  Chrome = Webkit and navigator.vendor.search(/google/i) isnt -1

  return {
    IE:IE,
    ltIE6:ltIE6,
    ltIE7:ltIE7,
    ltIE8:ltIE8,
    ltIE9:ltIE9,
    IE6:IE6,
    IE7:IE7,
    IE8:IE8,
    IE9:IE9,
    IE10:IE10,
    Firefox:window.sidebar,
    Opera:window.opera,
    Webkit:Webkit,
    Safari:Safari,
    Chrome:Chrome,
    Mobile:window.orientation
  }

#URL
url = do ->
  href = location.href.split('/')

  localRegex = /^\d+\.\d+\.\d+\.\d+/
  workRegex = /^.*\/pc\/[^\/]+\/.*$/

  for val , i in href
    if val is '' or i is href.length - 1 and val.indexOf('.')
      href.splice(i,1)

  if localRegex.test(location.hostname) is true or location.hostname.indexOf('localhost') isnt -1
    length = 2

  else if workRegex.test(location.href) is true
    length = 4

  else
    length = 1

  path = ''

  for j in [0..(length)]
    path += href[j]

    if j is 0
      path += '//'

    else
      path += '/'

  return path

#サイト判別
region = do ->
  href = location.href.split('/')
  clinic = {}
  clinic.tokyo = ['_','tct','theclinic.jp']
  clinic.yokohama = ['tcy','theclinic-yokohama.com']
  clinic.nagoya = ['tcn', 'theclinic-nagoya.com']
  clinic.osaka = ['tco','theclinic-osaka.com']
  clinic.fukuoka = ['tcf','theclinic-fukuoka.com']

  for i in [0...href.length]
    if href[i] isnt ''
      for key of clinic
        for j in [0...clinic[key].length]
          if href[i].indexOf(clinic[key][j]) isnt -1
            return key
    if i is href.length - 1
      return 'bd'
      break

#スムーススクロール関数モーション定義
jQuery.extend(
  jQuery.easing, {
    easeOutCubic:
      (x,t,b,c,d) ->
        c*((t=t/d-1)*t*t+1)+b
  }
)

scrollPanel =
  (target)->
    scrollPane = target.find('.scroll-pane')
    scrollContent = target.find('.scroll-content')

    #build slider
    scrollbar = target.find( '.scroll-bar' ).slider({
      orientation:'vertical'
      value:100
      slide:
        (event,ui) ->
          if scrollContent.height() > scrollPane.height()
            scrollContent.css('margin-top',Math.round((100 - ui.value) / 100 * (scrollContent.height() - scrollPane.height()) * -1) + 'px')
          else
            scrollContent.css( 'margin-top',0)
    })

    #append icon to handle
    handleHelper = scrollbar.find('.ui-slider-handle')
    .mousedown(
      ->
        scrollbar.height(handleHelper.height())
    )
    .mouseup(
      ->
        scrollbar.height('100%')
    )
    .wrap( '<div class="ui-handle-helper-parent"></div>' ).parent()

    #change overflow to hidden now that slider handles the scrolling
    scrollPane.css('overflow','hidden')

    #size scrollbar and handle proportionally to scroll distance
    sizeScrollbar = ->
      remainder = scrollContent.height() - scrollPane.height()
      proportion = remainder / scrollContent.height()
      handleSize = scrollPane.height() - (proportion * scrollPane.height())
      handleSize = 75

      scrollbar.find('.ui-slider-handle').css({
        height:handleSize
        'margin-top':-handleSize / 2
      })

      handleHelper.height('').css({
        'height':(scrollbar.height() - handleSize)
        'margin-top':handleSize
      })

    #reset slider value based on scroll content position
    resetValue = ->
      remainder = scrollPane.height() - scrollContent.height()
      topVal = if scrollContent.css('margin-top') is 'auto' then 0 else parseInt(scrollContent.css( 'margin-top' ))
      percentage = Math.round(topVal / remainder * 100)
      scrollbar.slider('value', 100 - percentage)

    #if the slider is 100% and window gets larger, reveal content
    reflowContent = ->
      showing = scrollContent.height() + parseInt(scrollContent.css( 'margin-top' ), 10)
      gap = scrollPane.height() - showing
      if gap > 0
        scrollContent.css( 'margin-top', parseInt(scrollContent.css( 'margin-top' ), 10 ) + gap)

    #change handle position on window resize
    $(window).resize(
      ->
        resetValue()
        sizeScrollbar()
        reflowContent()
        return
    )

    #init scrollbar size
    setTimeout(sizeScrollbar,10)#safari wants a timeout

$(->
  $id = $('body').attr('id')
  $class = $('body').attr('class')

  # if $id is 'members'
  #   listTemplate = $('#list-template').html()
  #
  #   $.ajax({
  #     url: 'https://www.theclinic-fukuoka.com/instagram.php'
  #     type: 'POST'
  #     dataType: 'json'
  #   }).done((data)->
  #     data = data.data
  #     $(data).each(
  #       (i, e)->
  #         console.log(e)
  #         data.url = ()->
  #           return e.images.thumbnail.url
  #
  #         data.alt = ()->
  #           return e.caption.text
  #
  #         data.link = ()->
  #           return e.link
  #
  #         contents = Mustache.render(listTemplate, data)
  #         return $('.js-instagram-list').append(contents)
  #     )
  #   )

  #背景高さ算出
  bgCheck = ->
    containerHeight = $('#container').outerHeight()
    contentHeight = $('#header').height() + $('#content').height()

    if(contentHeight > containerHeight)
      return $('#container').css('height','auto')

  bgCheck()

  $(window).on('load',
    ()->
      bgCheck()
      return
  )

  #フッター高さ取得
  footerHeight = ->
    add = 0
    height = $('#footer').outerHeight()
    $('#content').css('padding-bottom',height+add)
    $('#footer').css('height',height)
    return

  footerHeight()

  $(window).on('load',
    ()->
      footerHeight()
      return
  )

  $(window).resize(
    ->
      footerHeight()
      return
  )

  if location.hash isnt ''
    $(
      if (navigator.userAgent.indexOf('Opera') isnt -1)
      then (
        if document.compatMode is 'BackCompat'
        then 'body'
        else 'html'
      )
      else 'html,body'
    ).animate({
      scrollTop: $(location.hash).offset().top - $('#gnavi').outerHeight() - 20
    }, {
      duration: 1
    })

  #コンテンツフッタークリニックスライドショー
  if region isnt 'bd'
    $contentsSlide = $('#main aside #clinic .slide,#main aside .clinic .slide')

    if $contentsSlide.get(0) isnt undefined
      $(window).load(
        ->
          window.myFlux = new flux.slider($contentsSlide, {
            autoplay:false,
            pagination:false,
            transitions:['blocks2'],
            delay:3500
          })

          slideFlg = false

          offset = 100

          setTimeout(
            ->
              if $(window).height() + $(this).scrollTop() >= $contentsSlide.offset().top - offset and slideFlg is false
                window.myFlux.start()
                slideFlg = true
                return
            ,500
          )

          $(window).scroll(
            ->
              if $(window).height() + $(this).scrollTop() >= $contentsSlide.offset().top - offset
                if slideFlg is false
                  window.myFlux.start()
                  slideFlg = true
                  return
              else
                if slideFlg is true
                  window.myFlux.stop()
                  slideFlg = false
                  return
          )
          return
      )

  #IE幅変更時ズレ対策
  if _ua.IE
    if _ua.IE8
      $('#container').css({
        'padding-right':'1px'
      })

  # 背景画像1pxズレ問題解決(Safariのみスタイル変更)
  if _ua.Safari
    $('#container').css({
      'background-image':'url('+url+'img/pc/container_bg_s.png)'
    })

    $('#container > .wrapper').css({
      'background-image':'url('+url+'img/pc/container_bg2_s.png),url('+url+'img/pc/bg2.gif)'
    })

  #お悩みソート関数
  listSort = (target,i)->
    parent = target
    items = parent.find('li')

    $(target).css({
      'background':'url('+url+'img/pc/load.gif) center center no-repeat'
    })
    $(items).css({
      'visibility':'hidden'
    })

    separate = new Number(i)

    parentWidth = parent.innerWidth()
    #itemWidth = Math.round(parentWidth / separate) - 1
    itemWidth = Math.floor((parentWidth - (separate - 1)) / separate)
    totalWidth = (itemWidth * separate) + (separate - 1)
    widthDiff = parentWidth - totalWidth

    itemNum = parent.find('li').length
    colNum = Math.ceil(itemNum / separate)
    lastItemNum = itemNum - (colNum * separate)
    rest = itemNum % separate

    if rest is 0
      lastStartNum = separate * (colNum - 1) + 1
      lastEndNum = itemNum
    else
      lastStartNum = itemNum - rest + 1
      lastEndNum = itemNum

    reduceStartNum = 0
    reduceEndNum = 0

    parent.find('li').each(
      (e)->
        if e+1 <= separate
          if colNum > 2
            $(this).css({
              'border-top':'none',
              'border-bottom':'none'
            })
          else
            $(this).css({
              'border-top':'none'
            })
        if e+1 > separate and e+1 < lastStartNum - separate
          $(this).css({
            'border-bottom':'none'
          })
        if e+1 >= lastStartNum and e+1 <= lastEndNum
          $(this).css({
            'border-top':'none',
            'border-bottom':'none'
          })

        $(this).css({
          'width':itemWidth
        })

        if (e+1) % separate is 0
          $(this).css({
            'border-right':'none'
          })

          if separate isnt 1
            $(this).css({
              'width':$(this).width() + 1
            })
    )

    if widthDiff > 1
      for i in [1..(widthDiff - 1)]
        parent.find('li:nth-child('+separate+'n+'+i+')').each(
          ->
            $(this).css({
              'width':$(this).width() + 1
            })
        )

    $(window).load(
      ->
        k = 0
        for i in [0...colNum]
          itemHeights = new Array()
          itemHeightest = 0

          for j in [0...separate]
            if k >= itemNum
              break

            if $(items).find('dl').get(0) is undefined
              modifiedItem = $($(items).get((separate*i)+j)).find('span')
            else
              modifiedItem = $($(items).get((separate*i)+j)).find('dl')

            itemHeights[j] = modifiedItem.height()
            if itemHeights[j] > itemHeightest
              itemHeightest = itemHeights[j]
            k++

          for j in [0...separate]
            if $(items).find('dl').get(0) is undefined
              $($(items).get((separate*i)+j)).find('span').height(itemHeightest)
            else
              $($(items).get((separate*i)+j)).find('dl').height(itemHeightest)

        $(target).css({
          'background':'none'
        })
        $(items).css({
          'visibility':'visible'
        })
        return
    )
    return

  #辞書機能
  dictionary =
    ->
      keywords = {}
      descriptions = {}
      keyword = $('.keyword')
      description = $('#dictionary .description')

      $(window).load(
        ->
          j = 1

          for i in [1..keyword.length]
            keywords[i] = {
              top:$('#keyword'+i).offset().top
              left:$('#keyword'+i).offset().left
              width:$('#keyword'+i).outerWidth()
              height:$('#keyword'+i).outerHeight()
            }

          description.each(
            ->
              minWidth = 220

              $(this).width($(this).find('.title').outerWidth() + 44 + 4 + 10)
              $(this).children('.wrapper-outer').width($(this).find('.title').outerWidth() + 44 + 4)

              if $(this).find('.title').outerWidth() < minWidth
                $(this).find('.title').outerWidth(minWidth)

                $(this).width($(this).find('.title').outerWidth() + 44 + 4 + 10)
                $(this).children('.wrapper-outer').width($(this).find('.title').outerWidth() + 44 + 4)

              descriptions[j] = {
                width:$(this).width()
                height:$(this).height()
              }

              $(this).css({
                'display':'none'
                'opacity':1
                'position':'absolute'
                'top':keywords[j].top + keywords[j].height + 5
                'left':keywords[j].left + keywords[j].width - 10
                'z-index':1000
              })

              j++
              return
          )
          return
      )

      $(window).resize(
        ->
          j = 1

          for i in [1..keyword.length]
            keywords[i].top = $('#keyword'+i).offset().top
            keywords[i].left = $('#keyword'+i).offset().left

          description.each(
            ->
              $(this).css({
                'top':keywords[j].top + keywords[j].height + 5
                'left':keywords[j].left + keywords[j].width - 10
              })

              j++
              return
          )
          return
      )

      keyword.click(
        (e)->
          id = $(this).attr('id').slice(7)

          description.each(
            ->
              if $(this).attr('id') isnt 'description'+id
                $(this).hide('normal')
                return
              else
                $('#dictionary #description'+id).toggleClass('open')
                $('#dictionary #description'+id).toggle('normal')
                return
          )

          e.stopPropagation()
          return
      )

      $(document).click(
        ->
          $('.description.open').hide('normal')
          $('.description.open').removeClass('open')
          return
      )

  #個別ページ
  # サイトマップ
  # if $id is 'sitemap'
  #   $('#operation > section,#trouble > section').each(
  #     ->
  #       $(this).find('ul').each(
  #         ->
  #           listSort($(this),4)
  #           return
  #       )
  #       return
  #   )

  #class未定義ページ
  if $class is undefined or $class.indexOf('ie8 slvzr-first-of-type') is 0 or $class.indexOf('slvzr-first-of-type') is 1 or $class is 'sp'
    #インデックス
    if $id is 'index'
      $('.mainimg__main').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 900,
        arrows: true,
        asNavFor: '.mainimg__sub',
      })

      $('.mainimg__sub').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 900,
        slidesToShow: 6,
        centerMode: true,
        asNavFor: '.mainimg__main',
        focusOnSelect: true,
      })

      echo.init({
        offset: 1500,
        offsetHorizontal: 5000
      })

      #トップスライド
      obj = $('.bxslider').bxSlider({
        auto: true
        mode: 'fade'
        pagerCustom: '.bx-pager'
        pause: 5000
        controls: false
        onSlideAfter: ->
          obj.startAuto()
          return
      })
      $('.bx-pager li a').BlackAndWhite({hoverEffect: true})
      $('.bx-pager li a').children('canvas').show()
      obj2 = $('#slider-side').bxSlider({
        auto: true
        autoControls: false
        controls: true
        pager: false
        mode: 'vertical'
        speed: 1000
        autoHover: true
        pause: 4000
        easing: 'swing'
        displaySlideQty: 3
        moveSlideQty: 1
        onSlideAfter: ->
          obj2.startAuto()
          return
      })

      obj = $('.case-list').bxSlider({
        slideWidth: 230
        minSlides: 2
        maxSlides: 2
        moveSlides: 1
        slideMargin: 6
        speed: 1000
        pause: 5000
        pager: false
        controls: true
      })

      if region isnt 'bd'
        #コンテンツ配置
        $('#operation > section').each(
          ->
            $(this).find('ul').each(
              ->
                listSort($(this),3)
                return
            )
            return
        )

        parent = $('#trouble > div')
        rowNum = 4
        heights = new Array()

        parent.each(
          (e)->
            $(this).find('ul').each(
              ->
                listSort($(this),rowNum)
                $(this).parent('.wrapper').parent('div').css('visibility','visible')
                return
            )
            return
        )

        $(window).load(
          ->
            parent.hide()
            $('#trouble > .active').show()
            return
        )

        $('#trouble .tab li a').on('click',
          (e)->
            if not $(this).parent('li').hasClass('active')
              activeImg = $(this).parent('li').siblings('.active').children('a').children('img')

              activeImg.attr('src',activeImg.attr('src').replace('_a',''))
              $(this).parents('li').siblings('li').removeClass('active')

              $(this).children('img').attr('src',$(this).children('img').attr('src').replace('.gif','_a.gif'))
              $(this).parent('li').addClass('active')

              $(this).parents('.tab').siblings('div').children('.wrapper').removeClass('close')
              targetDiv = $(this).parents('.tab').siblings($(this).attr('href'))

              $(this).parents('.tab').siblings('div').hide()
              targetDiv.children('.wrapper').addClass('close')
              targetDiv.show()
            e.preventDefault()
            return
        )

      if region is 'bd'
        rowNum = 3
        i = 0
        j = 0
        heights = new Array()
        heights[j] = new Array()

        $('#operation > section').each(
          ->
            $(this).find('ul').each(
              ->
                listSort($(this),3)
                return
            )
            return
        )
        return
  else
    if $class.indexOf('photo') isnt -1 and $id is 'details'
      if document.referrer is url.replace('photo/', '') or document.referrer.indexOf('photo/') isnt -1
        $(
          if (navigator.userAgent.indexOf('Opera') isnt -1)
          then (
            if document.compatMode is 'BackCompat'
            then 'body'
            else 'html'
          )
          else 'html,body'
        ).animate({
          scrollTop: $('.p-casedetails').offset().top - $('#gnavi').outerHeight()
        }, 0)

    #その他ページ
    $('a[href^="#"]').click(
      (e)->
        $(
          if (navigator.userAgent.indexOf('Opera') isnt -1)
          then (
            if document.compatMode is 'BackCompat'
            then 'body'
            else 'html'
          )
          else 'html,body'
        ).animate({
          scrollTop:$($(this).attr('href')).offset().top - $('#gnavi').height() - 20
        }, {
          duration:500
        })

        e.preventDefault()
        return
    )

    if $('#sub .s-matome-list').length isnt 0
      matomeSlide = $('#sub .s-matome-list').bxSlider({
        auto: true
        autoControls: false
        controls: true
        pager: false
        mode: 'vertical'
        speed: 1000
        autoHover: true
        pause: 4000
        easing: 'swing'
        displaySlideQty: 3
        moveSlideQty: 1
        onSlideAfter: ->
          matomeSlide.startAuto()
          return
      })

    #コラム
    if $class.indexOf('column') isnt -1
      $('.sub-menu').siblings('a').on('click',
        (e)->
          e.preventDefault()
          $('.sub-menu').not(':animated').slideUp()
          $(this).siblings('.sub-menu').slideDown()
      )

      if $id is 'entry'
        if $('#dictionary').length isnt 0
          dictionary()

        $('.related-keyword').after('<div class="social"><ul><li class="twitter"></li><li class="facebook"></li><li class="googleplus"></li><li class="hatena"></li></ul></div>')
        $('div.social .twitter').socialbutton(
          'twitter', {
            button:'horizontal'
        })
        $('div.social .facebook').socialbutton(
          'facebook_like', {
            button:'button_count',
            url:location.href
        })
        $('div.social .googleplus').socialbutton(
          'google_plusone', {
            lang:'ja',
            size:'medium'
        })
        $('div.social .hatena').socialbutton(
          'hatena', {
            button:'standard-balloon'
        })

        listSort($('.m-trouble-list'), 4)

    #交通費補助
    if $id is 'transportation'
      heights = new Array()

      $('#example > section').each(
        (e)->
          i = e

          heights[i] = new Array()
          $(this).find('table').each(
            (e)->
              j = e
              heights[i][j] = $(this).outerHeight()
              return
          )
          return
      )

      maximumHeight = new Array()

      for i in [0..heights.length - 1]
        maximumHeight[i] = 0

        for j in [0..heights[i].length - 1]
          if heights[j][i] >= maximumHeight[i]
            maximumHeight[i] = heights[j][i]

      $('#example > section').each(
        ->
          $('table',this).each(
            (e)->
              if $(this).outerHeight() < maximumHeight[e]
                diff = maximumHeight[e] - $(this).height()
                $target = $('tbody tr:last-child th')

                $(this).find($target).each(
                  ->
                    $(this).css({
                      'height':$(this).height() + diff
                    })
                )
              return
          )
          return
      )

    #診療内容
    if $class.indexOf('contents') isnt -1
      #トップ
      if $id is 'index'
        $('#operation > div,#trouble > div').each(
          ->
            $(this).find('ul').each(
              ->
                listSort($(this),4)
                return
            )
            return
        )

        $('#trouble-operation .tab li a').each(
          ->
            $(this).off()
        )

        $('#trouble-operation .tab li a').on('click',
          (e)->
            if not $(this).parent('li').hasClass('is-active')
              activeImg = $(this).parent('li').siblings('.is-active').children('a').children('img')

              activeImg.attr('src',activeImg.attr('src').replace('_a',''))
              $(this).parents('li').siblings('li').removeClass('is-active')

              $(this).children('img').attr('src',$(this).children('img').attr('src').replace('.gif','_a.gif'))
              $(this).parent('li').addClass('is-active')

              $wrapper = $(this).parents('.tab').siblings('.wrapper')
              targetDiv = $wrapper.children($(this).attr('href'))

              $wrapper.children('.trouble-operation-item').removeClass('is-active')
              targetDiv.addClass('is-active')
            e.preventDefault()
            return
        )

      # トビラ
      if $class.indexOf('category') isnt -1
        $('#case .tab li a').each(
          ->
            $(this).off()
        )

        $('#case .tab li a').on('click',
          (e)->
            if not $(this).parent('li').hasClass('is-active')
              $(this).parents('li').siblings('li').removeClass('is-active')
              $(this).parent('li').addClass('is-active')

              $wrapper = $(this).parents('.tab').siblings('.case-inner')
              targetDiv = $wrapper.children($(this).attr('href'))

              $wrapper.children('.case-list').removeClass('is-active')
              targetDiv.addClass('is-active')
            e.preventDefault()
            return
        )

        if $id isnt 'breast-hip'
          scrollPanel($('#ranking .trouble'))

      #お悩み
      if $id is 'trouble'
        if $('#dictionary').length isnt 0
          dictionary()

        recommendItem = $('#recommend ul li')

        recommendItem.hover(
          (e)->
            $(this).toggleClass('close')
            $(this).children('section').children('section').stop().slideToggle()
            e.preventDefault()
            return
        )

        if region isnt 'bd'

          $('#sub #menu > ul > li').each(
            ->
              if $(this).hasClass('active')
                $(this).find('dd').show()
                return
          )

          $('#sub #menu > ul > li dl dt a').on('click',
            (e)->
              $(this).parents('ul').children('li').each(
                ->
                  $(this).find('img').attr('src',$(this).find('img').attr('src').replace('_a.gif','.gif'))
                  return
              )

              #if $(this).parents('li:not(.active)').get(0) isnt undefined
              $(this).find('img').attr('src',$(this).find('img').attr('src').replace('.gif','_a.gif'))

              $('#sub #menu > ul > li > dl dd').slideUp()
              $(this).parent('dt').siblings('dd').stop().slideToggle()
              e.preventDefault()
              return
          )

      #施術
      if $class.indexOf('operation') isnt -1
        #トップ
        if $id is 'index'
          if $('#dictionary').length isnt 0
            dictionary()

          $('#effect section, .m-operation').each(
            ->
              $(this).find('ul').each(
                ->
                  listSort($(this),4)
                  return
              )
              return
          )

        #ベイザーハイデフ
        if $class.indexOf('vaserhidef') isnt -1
          #ベイザーハイデフ BMI測定
          $('#bmi #button #submit').click(
            ->
              $error = 0
              if $('#bmi #height_input').val() isnt undefined
                $height = $('#bmi #height_input').val()

                if $height.match(///^([0-9]{3}|[0-9]{3}\.[0-9]+)$///)
                else
                  $error = 1
                  $height_text = '身長は半角数字のみ入力可能です。'

              if $('#bmi #weight_input').val() isnt undefined
                $weight = $('#bmi #weight_input').val()

                if $weight.match(///^([0-9]{2,3}|[0-9]{2,3}\.[0-9]+)$///)
                else
                  $error = 1
                  $weight_text = '体重は半角数字のみ入力可能です。'

              if $error is 1
                $text = 'エラー\n'

                if $height_text isnt undefined
                  $text += '\n'+$height_text

                if $weight_text isnt undefined
                  $text += '\n'+$weight_text

                alert($text)

              bmi = (weight,height)->
                height = height / 100
                weight / (height * height)

              if $weight isnt undefined and $height isnt undefined and $error isnt 1
                $result = bmi($weight , $height)
                $result = Math.round($result)

                $('#bmi #message').remove()

                $('#bmi #bmiform').after('<div id="message"></div>')

                $('#bmi #message').append('<p>あなたのBMIは<span>'+$result+'</span>です</p>')

                if $result >= 28
                  $('#bmi #message').append('<p>ダイエットやワークアウトをするか、ベイザーで徹底的に脂肪を吸引することを検討してください。</p>')
                  return

                else if $result < 28 and $result >= 23
                  $('#bmi #message').append('<p>あなたのカラダにはベイザーハイデフを施すことで、目に見えた結果がでます。今、決意すれば、10年後の人生は大きく変わっていることでしょう。</p>')
                  return

                else if $result <= 22 and $result >= 20
                  $('#bmi #message').append('<p>あなたのカラダは、ベイザーハイデフを行う価値が最も高いグループです。ベイザーハイデフで最高のBODY Value (ボディバリュー)を生み出しましょう。</p>')
                  return

                else if $result <= 19 and $result >= 18
                  $('#bmi #message').append('<p>あなたのカラダに単純な痩身施術は不要です。脂肪吸引は受けないでください。ベイザーハイデフならあなたの脂肪をバストなど最適な位置へ再配置することができます。</p>')
                  return

                else if $result <= 17
                  $('#bmi #message').append('<p>この状態が続くと危険です。あなたは健康的な社会生活を行うために体重を増やす必要があります。</p>')
                  return
          )

          $('#bmi #button #retry').click(
            ->
              $('#bmi #height_input').attr('value' ,'')
              $('#bmi #weight_input').attr('value' ,'')
              $('#bmi #message').remove()
              return
          )

      #キャンペーン
      if $id is 'campaign'
        campaignItem = $('#campaignlist > .folding > section > .title')

        if campaignItem.length is 1
          campaignItem.siblings('.wrapper').css('display','block')

        campaignItem.click(
          (e)->
            $(this).siblings('.wrapper').slideToggle()
            e.preventDefault()
            return
        )

    #症例
    if $class.indexOf('case') isnt -1
      $tabItem = $('.s-ranking-tab').find('li')
      $tabImg = $tabItem.find('img')
      $rankingItem = $('.s-ranking-item')

      $tabImg.on('mouseover',
        ()->
          $tabImg.each(
            ()->
              if not $(this).parents('.tab-item').hasClass('is-active')
                $(this).attr('src', $(this).attr('src').replace('_h.gif', '.gif'))
          )

          if not $(this).parents('.tab-item').hasClass('is-active')
            $(this).attr('src', $(this).attr('src').replace('.gif', '_h.gif'))
      )

      $tabImg.on('mouseleave',
        ()->
          $tabImg.each(
            ()->
              if not $(this).parents('.tab-item').hasClass('is-active')
                $(this).attr('src', $(this).attr('src').replace('_h.gif', '.gif'))
          )
      )

      $tabItem.on('click',
        ()->
          $tabItem.each(
            ()->
              $(this).find('img').attr('src', $(this).find('img').attr('src').replace('_h.gif', '.gif'))
              $(this).removeClass('is-active')
          )

          $(this).addClass('is-active')
          $(this).find('img').attr('src', $(this).find('img').attr('src').replace('.gif', '_h.gif'))

          $rankingItem.each(
            ()->
              $(this).removeClass('is-active')
          )

          $target = $('.s-ranking-wrapper').find('.'+$(this).find('a').attr('name'))
          $target.addClass('is-active')
      )

      $pickupItem = $('.m-pickup-item')

      $pickupItem.on('mouseover',
        ()->
          $pickupItem.removeClass('is-active')

          $(this).addClass('is-active')
      )

      $categoryTabItem = $('.m-category-tab').find('.tab-item')
      $categoryItem = $('.m-category-item')

      $categoryTabItem.on('mouseover',
        ()->
      )

      $categoryTabItem.on('click',
        ()->
          $categoryTabItem.removeClass('is-active')

          $(this).addClass('is-active')

          $categoryItem.each(
            ()->
              $(this).removeClass('is-active')
          )

          $target = $('.m-category-wrapper').find('.'+$(this).find('a').attr('name'))
          $target.addClass('is-active')
      )

      $genreTabItem = $('.m-genre-tab').find('.tab-item')
      $genreItem = $('.m-genre-item')

      $genreTabItem.on('click',
        ()->
          $genreTabItem.removeClass('is-active')

          $(this).addClass('is-active')

          $genreItem.each(
            ()->
              $(this).removeClass('is-active')
          )

          $target = $('.m-genre-inner').find('.'+$(this).find('a').attr('name'))
          $target.addClass('is-active')
      )
      #トップ
      if $id is 'index'
        $('.tab-contents').each(
          ->
            $(this).find('ul').each(
              ->
                listSort($(this),4)
                return
            )
            return
        )

        $('#trouble-operation .tab li a').each(
          ->
            $(this).off()
        )

        $('#trouble-operation .tab li a').on('click',
          (e)->
            if not $(this).parent('li').hasClass('is-active')
              activeImg = $(this).parent('li').siblings('.is-active').children('a').children('img')

              activeImg.attr('src',activeImg.attr('src').replace('_a',''))
              $(this).parents('li').siblings('li').removeClass('is-active')

              $(this).children('img').attr('src',$(this).children('img').attr('src').replace('.gif','_a.gif'))
              $(this).parent('li').addClass('is-active')

              $wrapper = $(this).parents('.tab').siblings('.wrapper')
              targetDiv = $wrapper.children($(this).attr('href'))

              $wrapper.children('.trouble-operation-item').removeClass('is-active')
              targetDiv.addClass('is-active')
            e.preventDefault()
            return
        )

      # トビラ
      if $class.indexOf('category') isnt -1
        $('#case .tab li a').each(
          ->
            $(this).off()
        )

        $('#case .tab li a').on('click',
          (e)->
            if not $(this).parent('li').hasClass('is-active')
              $(this).parents('li').siblings('li').removeClass('is-active')
              $(this).parent('li').addClass('is-active')

              $wrapper = $(this).parents('.tab').siblings('.case-inner')
              targetDiv = $wrapper.children($(this).attr('href'))

              $wrapper.children('.case-list').removeClass('is-active')
              targetDiv.addClass('is-active')
            e.preventDefault()
            return
        )

        if $id isnt 'breast-hip'
          scrollPanel($('#ranking .trouble'))

    #料金
    if $class.indexOf('price') isnt -1
      $('#sub #menu > ul > li').each(
        ->
          if $(this).hasClass('active')
            $(this).children('dl').children('dd').show()
            return
      )

      $('#sub #menu > ul > li dl dt a').on('click',
        (e)->
          $(this).parents('ul').children('li').each(
            ->
              $(this).children('dl').each(
                ->
                  if $id isnt 'counseling' and $id isnt 'payment' and $id isnt 'pointservice'
                    $(this).children('dt').find('img').attr('src',$(this).children('dt').find('img').attr('src').replace('_a.gif','.gif'))
                  return
              )
              return
          )

          if $id isnt 'counseling' and $id isnt 'payment' and $id isnt 'pointservice'
            $(this).find('img').attr('src',$(this).find('img').attr('src').replace('.gif','_a.gif'))

          $('#sub #menu > ul > li > dl > dd').slideUp()
          $(this).parent('dt').siblings('dd').stop().slideToggle()
          e.preventDefault()
          return
      )

      #トップ
      if $id is 'index'
        $('#pricelist section section').each(
          ->
            $(this).find('ul').each(
              ->
                listSort($(this),4)
                return
            )
            return
        )

      #施術ページ
      if $id is 'operation'
        campaignItem = $('div#campaign ul > li > section > .title')

        campaignItem.click(
          (e)->
            $(this).siblings('.wrapper').slideToggle()
            e.preventDefault()
            return
        )

    #ドクター紹介
    if $class.indexOf('doctor') isnt -1
      if $id isnt 'index'
        loadScript =
          ->
            $.ajax({
              url: 'https://www.youtube.com/player_api/'
              dataType: 'script'
              success:
                (data)->
                  window.onYouTubeIframeAPIReady =
                    ->
                      id = $($('#movie ol').find('a').get(0)).attr('href').replace(/https?:\/\/[0-9a-zA-Z\.]+\//, '')
                      loadPlayer(id)
              error:
                (xhr, status, thrown)->
                  loadScript()
            })

        loadScript()

        loadPlayer =
          (id)->
            if not window.ytplayer
              window.ytplayer = new YT.Player('player', {
                width: '438'
                height: '243'
                videoId: id
                playerVars:{
                  hl:'ja_JP'
                  fs:1
                  hd:1
                  rel:0
                  showinfo:0
                  autohide:1
                  theme:'light'
                }
              })
            else
              ytplayer.loadVideoById(id)

        onPlayerReady =
          (event)->
            event.target.playVideo()

        $movie = $('#movie')

        $movie.find('a').click(
          (e)->
            e.preventDefault()

            $movie.find('li').removeClass('active')
            $movie.find('img').each(
              ->
                $(this).attr('src',$(this).attr('src').replace('_a.jpg','.jpg'))
            )

            $(this).parent('li').addClass('active')
            $(this).find('img').attr('src',$(this).find('img').attr('src').replace('.jpg','_a.jpg'))

            id = $(this).attr('href').replace(/https?:\/\/[0-9a-zA-Z\.]+\//, '')
            loadPlayer(id)
        )

    #アクセス・地図
    if $class.indexOf('access') isnt -1

      if $id isnt 'index'
        $('iframe').css('visibility','hidden')

        if $id is 'tokyo'
          imgName = 'TC_img'
        if $id is 'yokohama'
          imgName = 'TCY_img'
        if $id is 'nagoya'
          imgName = 'TCN_img'
        if $id is 'fukuoka'
          imgName = 'TCF_img'
        if $id is 'osaka'
          imgName = 'TCO_img'

        $mainSlide = $('#slide')

        $mainSlide.slidesjs( {
          width:414,
          height:311,
          navigation: {
            active:false,
            effect:'fade'
          },
          pagination: {
            active:true,
            effect:'fade'
          },
          play: {
            active:true,
            effect:'fade',
            #auto:true
          },
          effect: {
            fade: {
              speed:300,
              crossfade:true
            }
          }
          ,
          callback: {
            start:
              ->
                $('.slidesjs-pagination-item a').children('canvas').fadeIn()
                $('.slidesjs-pagination-item .active').children('canvas').fadeOut()
                return
          },
          thumbnail: {
            format:'img',
            path:'../img',
            name:imgName,
            extension:'_s.png'
          }
        })

        mainSlideFlg = false

        $(window).load(
          ->
            setTimeout(
              ->
                if $(this).scrollTop() < $mainSlide.offset().top + $('#activities').height()
                  if mainSlideFlg is false
                    $('.slidesjs-play').trigger('click')
                    mainSlideFlg = true
                    return
              ,5000
            )

            $(this).scroll(
              ->
                if $(this).scrollTop() < $mainSlide.offset().top + $('#activities').height()
                  if mainSlideFlg is false
                    setTimeout(
                      ->
                        $('.slidesjs-play').trigger('click')
                        return
                      ,5000
                    )
                    mainSlideFlg = true
                    return
                else
                  if mainSlideFlg is true
                    $('.slidesjs-stop').trigger('click')
                    mainSlideFlg = false
                    return
            )
            return
        )

        $('.slidesjs-navigation').css({
          'display':'block',
          'height':0,
          'overflow':'hidden'
        })

        $('.slidesjs-pagination').on('click',
          ->
            $('.slidesjs-navigation').css({
              'display':'block'
            })

            setTimeout(
              ->
                $('.slidesjs-play').trigger('click')
                return
              ,5000
            )
            return
        )

        $('.slidesjs-pagination-item a:not(.active)').hover(
          ->
            $(this).children('canvas').fadeOut()
            return
          ,->
            if $(this).hasClass('active')
              $(this).children('canvas').hide()
            else
              $(this).children('canvas').fadeIn()
            return
        )

        $('.slidesjs-pagination-item a').click(
          ->
            $(this).children('canvas').hide()
            return
        )

        $(window).load(
          ->
            $('.slidesjs-pagination-item a').each(
              ->
                $(this).BlackAndWhite({
                  hoverEffect:false
                })
                return
            )
            $('.slidesjs-pagination-item .active').children('canvas').hide()

            $('iframe').fadeOut(0).css('visibility','visible').fadeIn()
            return
        )

    #お悩み相談集
    if $class.indexOf('qa') isnt -1
      $('#sub #menu > ul > li').each(
        ->
          if $(this).hasClass('active')
            $(this).children('dl').children('dd').show()
            return
      )

      $('#sub #menu > ul > li dl dt a').on('click',
        (e)->
          $(this).parents('ul').children('li').each(
            ->
              $(this).children('dl').each(
                ->
                  if $id isnt 'latest'
                    $(this).children('dt').find('img').attr('src',$(this).children('dt').find('img').attr('src').replace('_a.gif','.gif'))
                  return
              )
              return
          )

          if $id isnt 'latest'
            $(this).find('img').attr('src',$(this).find('img').attr('src').replace('.gif','_a.gif'))


          $('#sub #menu > ul > li > dl > dd').slideUp()
          $(this).parent('dt').siblings('dd').stop().slideToggle()
          e.preventDefault()
          return
      )

      #トップ
      if $id is 'index'
        $('#catlist section section').each(
          ->
            $(this).find('ul').each(
              ->
                listSort($(this),4)
                return
            )
            return
        )

      #詳細
      if $id is 'entry'
        if $('#dictionary').length isnt 0
          dictionary()

    if $class.indexOf('movie') isnt -1
      $('#sub #menu > ul > li').each(
        ->
          if $(this).hasClass('active')
            $(this).children('dl').children('dd').show()
            return
      )

      $('#sub #menu > ul > li dl dt a').on('click',
        (e)->
          $(this).parents('ul').children('li').each(
            ->
              $(this).children('dl').each(
                ->
                  if $id isnt 'latest'
                    $(this).children('dt').find('img').attr('src',$(this).children('dt').find('img').attr('src').replace('_a.gif','.gif'))
                  return
              )
              return
          )

          if $id isnt 'latest'
            $(this).find('img').attr('src',$(this).find('img').attr('src').replace('.gif','_a.gif'))


          $('#sub #menu > ul > li > dl > dd').slideUp()
          $(this).parent('dt').siblings('dd').stop().slideToggle()
          e.preventDefault()
          return
      )

      if $('.thumb-wrapper').get(0)
        $list = $('.thumb-wrapper').children('ul[class$=-list]')
        $list.each(
          ->
            $(this).width($(this).children('li').outerWidth(true) * $(this).children('li').length)
        )

        showAll = false
        clickable = true
        param.limit = 1

        $('.next').addClass('is-active')

        $('.next').click(
          ->
            if clickable
              clickable = false
              $target = $(this).parents('.thumb-wrapper').children('ul[class$=-list]')

              if $target.width() + parseInt($target.css('left')) <= $target.parent('.thumb-wrapper').width()
                clickable = true

              if $target.children('li').length < parseInt($target.siblings('input[type=hidden]').val())
                $(this).addClass('is-active')
              $(this).siblings('.nav-item').addClass('is-active')

              if $target.width() + parseInt($target.css('left')) > $target.parent('.thumb-wrapper').width()
                $target.animate({
                  left:parseInt($target.css('left')) + ($target.children('li').outerWidth(true) * -1) * param.limit
                }, {
                  duration:500
                  complete:
                    ->
                      if $target.width() + parseInt($target.css('left')) <= $target.parent('.thumb-wrapper').width()
                        $target.siblings('.nav').children('.next').removeClass('is-active')

                      clickable = true
                })
        )

        $('.prev').click(
          ->
            if clickable
              clickable = false
              $target = $(this).parents('.thumb-wrapper').children('ul[class$=-list]')

              if parseInt($target.css('left')) < 0
                $target.animate({
                  left:parseInt($target.css('left')) + ($target.children('li').outerWidth(true)) * param.limit
                }, {
                  duration:500
                  complete:
                    ->
                      if parseInt($target.css('left')) is 0
                        $target.siblings('.nav').children('.prev').removeClass('is-active')

                      clickable = true
                })
                $(this).siblings('.next').addClass('is-active')

              if parseInt($target.css('left')) is 0
                $(this).removeClass('is-active')
                clickable = true
        )

      if $('#movie').get(0)

        loadScript =
          ->
            $.ajax({
              url:'https://www.youtube.com/player_api/'
              dataType:'script'
              success:
                (data)->
                  window.onYouTubeIframeAPIReady =
                    ->
                      id = $($('#movie .theme-list').find('a').get(0)).attr('href').replace(/https?:\/\/[0-9a-zA-Z\.]+\//, '')
                      loadPlayer(id)
              error:
                (xhr, status, thrown)->
                  loadScript()
            })

        loadScript()

        loadPlayer =
          (id)->
            if not window.ytplayer
              window.ytplayer = new YT.Player('player', {
                width: '384'
                height: '216'
                videoId: id
                playerVars: {
                  hl:'ja_JP'
                  fs:1
                  hd:1
                  rel:0
                  showinfo:0
                  autohide:1
                  theme:'light'
                }
              })
            else
              ytplayer.loadVideoById(id)

        onPlayerReady =
          (event)->
            event.target.playVideo()

        $title = $('.movie-wrapper .title')
        $link = $title.children('a')

        $('#movie .theme-list').find('a').click(
          (e)->
            id = $(this).parents('li').attr('id')
            href = $link.attr('href').replace(/\/(no\d+)\/$/,'/'+id+'/')

            $link.attr('href',href)

            $link.text($(this).find('.title').text())

            $(this).closest('.theme-list').children('.theme-item').removeClass('is-active')

            if not $(this).parent('li').hasClass('is-active')
              $(this).parent('li').addClass('is-active')

            id = $(this).attr('href').replace(/https?:\/\/[0-9a-zA-Z\.]+\//, '')
            loadPlayer(id)
            e.preventDefault()
        )

      if $('.tab').get(0)
        item = $('.tab-item')
        wrapper = $('.category-list-wrapper')

        item.children('a').each(
          ->
            $(this).off()
        )

        item.children('a').on('click',
          (e)->
            target = $(this).parent('.tab-item')
            img = $(this).children('img')
            list = $($(this).attr('href'))

            item.removeClass('is-active')
            wrapper.removeClass('is-active')
            target.addClass('is-active')
            list.addClass('is-active')
            img.each(
              ->
                if $(this).attr('src').indexOf('_a.gif') is -1
                  $(this).attr('src',$(this).attr('src').replace('.gif','_a.gif'))
            )

            item.not('.is-active').find('img').each(
              ->
                $(this).attr('src',$(this).attr('src').replace('_a.gif','.gif'))
            )

            e.preventDefault()
        )

      if $('.view').get(0)
        $('.view').find('a').click(
          (e)->
            if $(this).parent('.view-item').hasClass('hover')
              $('.view').find('.view-item').toggleClass('hover')
              $('.view').find('.view-item').each(
                ->
                  if $(this).find('img').attr('src').indexOf('_a') isnt -1
                    $(this).find('img').attr('src',$(this).find('img').attr('src').replace('_a.gif','.gif'))
                  else
                    $(this).find('img').attr('src',$(this).find('img').attr('src').replace('.gif','_a.gif'))
              )

              if $(this).parent('.view-item').hasClass('list')
                $('.is-tile').removeClass('is-tile').css('opacity','0').addClass('is-list').animate({
                  opacity:1
                }, {
                  duration:500
                })
              else
                $('.is-list').removeClass('is-list').css('opacity','0').addClass('is-tile').animate({
                  opacity:1
                }, {
                  duration:500
                })


            e.preventDefault()
        )

      if $('.more').get(0)

        $('.more').on('click',
          (e)->
            $target = $(this).siblings('ul[class*=-list]')
            getData(param).done(
              (data)->
                data = data.replace(/\r\n/g,'')
                data = data.replace(/\n/g,'')
                $data = $(data)
                $data.css('opacity','0')
                $target.append($data)
                $data.animate({
                  opacity:1
                }, {
                  duration:500
                })
            )
            e.preventDefault()
        )

      if $id is 'details'
        $('.movie-wrapper').after('<div class="m-social social"><ul><li class="twitter social-item"></li><li class="facebook social-item"></li><li class="googleplus social-item"></li><li class="hatena social-item"></li></ul></div>')
        $('div.social .twitter').socialbutton(
          'twitter', {
            button: 'horizontal'
        })

        $('div.social .facebook').socialbutton(
          'facebook_like', {
            button: 'button_count'
            url: location.href
        })

        $('div.social .googleplus').socialbutton(
          'google_plusone', {
            lang: 'ja'
            size: 'medium'
        })

        $('div.social .hatena').socialbutton(
          'hatena', {
            button: 'standard-balloon'
        })

    if $class.indexOf('activities') isnt -1
      $('#sub #menu > ul > li').each(
        ->
          if $(this).hasClass('active')
            $(this).children('dl').children('dd').show()
            return
      )

      clickCount = 0

      $('#sub #menu > ul > li dl dt a').on('click',
        (e)->
          e.preventDefault()

          $('#sub #menu > ul > li > dl > dd').slideUp()
          $(this).parent('dt').siblings('dd').stop().slideToggle()
          clickCount++
          return
      )

      $('#sub #menu > ul > li dl dd .load-more').find('a').on('focus mouseover',
        (e)->
          $(this).off()
      )

      displayNum = 10
      listLength = $('#sub #menu > ul > li dl dd ul li').length

      $('#sub #menu > ul > li dl dd ul li').each(
        ()->
          if $(this).index() >= displayNum
            $(this).hide()
      )

      $('#sub #menu > ul > li dl dd .load-more').on('click',
        (e)->
          e.preventDefault()

          $('#sub #menu > ul > li dl dd ul li').each(
            ()->
              if $(this).index() < (displayNum * (clickCount + 1))
                $(this).show()
          )

          if (displayNum * (clickCount + 1)) > listLength
            $('#sub #menu > ul > li dl dd .load-more').hide()

          clickCount++
      )
    if $class.indexOf('campaign') isnt -1
      if $class.indexOf('liposuction') isnt -1
        currentCategory = 'liposuction'
      else if $class.indexOf('breast-hip') isnt -1
        currentCategory = 'breast-hip'
      else if $class.indexOf('antiaging') isnt -1
        currentCategory = 'antiaging'

      dt = new Date()
      current_month = dt.getMonth() + 1
      current_date = dt.getDate()
      current_end_dt = new Date(current_year, current_month, 0)
      current_end_date = current_end_dt.getDate()

      $('body').addClass('month'+current_month)

      if current_date >= 20
        $('body').addClass('soon')

      $('.anchor-item').find('a').on('click.changeForm',
        ()->
          $('.button').each(
            ()->
              if $(this).find('a').attr('href').indexOf('monitor.php') isnt -1 and $(this).find('a').attr('href').indexOf('?') isnt -1
                $(this).find('a').attr('href', $(this).find('a').attr('href').slice(0, $(this).find('a').attr('href').indexOf('?'))+'?category='+currentCategory)
          )

          if $(this).attr('data-params') isnt undefined
            params = $(this).attr('data-params')

            $('.button').each(
              ()->
                if $(this).find('a').attr('href').indexOf('monitor.php') isnt -1
                  $(this).find('a').attr('href', $(this).find('a').attr('href').slice(0, $(this).find('a').attr('href').indexOf('?')))
                  $(this).find('a').attr('href', $(this).find('a').attr('href')+'?'+params)
            )
      )

    if $('.m-matome.m-sub-contents').length isnt -1
      i = 2
      parent = $('.m-matome-list')
      items = parent.find('li').not('li li')

      $(parent).css({
        'background':'url('+url+'img/pc/load.gif) center center no-repeat'
      })
      $(items).css({
        'visibility':'hidden'
      })

      separate = i

      parentWidth = parent.innerWidth()
      itemWidth = Math.floor((parentWidth - (separate - 1)) / separate)
      totalWidth = (itemWidth * separate) + (separate - 1)
      widthDiff = parentWidth - totalWidth

      itemNum = items.length
      colNum = Math.ceil(itemNum / separate)
      lastItemNum = itemNum - (colNum * separate)
      rest = itemNum % separate

      if rest is 0
        lastStartNum = separate * (colNum - 1) + 1
        lastEndNum = itemNum
      else
        lastStartNum = itemNum - rest + 1
        lastEndNum = itemNum

      reduceStartNum = 0
      reduceEndNum = 0

      if widthDiff > 1
        for i in [1..(widthDiff - 1)]
          parent.find('li:nth-child('+separate+'n+'+i+')').each(
            ->
              $(this).css({
                'width':$(this).width() + 1
              })
          )

      $(window).on('load',
        ()->
          k = 0
          for i in [0...colNum]
            itemHeights = new Array()
            itemHeightest = 0

            for j in [0...separate]
              if k >= itemNum
                break

              if $(items).find('dl').get(0) is undefined
                modifiedItem = $($(items).get((separate*i)+j)).find('span')
              else
                modifiedItem = $($(items).get((separate*i)+j)).find('dl')

              itemHeights[j] = modifiedItem.height()
              if itemHeights[j] > itemHeightest
                itemHeightest = itemHeights[j]
              k++

            for j in [0...separate]
              if $(items).find('dl').get(0) is undefined
                $($(items).get((separate*i)+j)).find('span').height(itemHeightest)
              else
                $($(items).get((separate*i)+j)).find('dl').height(itemHeightest)

          $(parent).css({
            'background':'none'
          })
          $(items).css({
            'visibility':'visible'
          })
          return
      )
      return
)
